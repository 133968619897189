<template>
  <div>
    <div>
      <system-bar></system-bar>
      <admin-menu></admin-menu>
    </div>
    <div>
      <v-card>
        <v-card-text>
        <v-container>
         <v-row>
              <v-col>
                <v-text-field
                  v-model="commission"
                  label="Inspector's commission (Baht/Case)"
                  maxlength="4"
                  id="commission"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-row>
              <v-col>
                <v-btn  class="mr-4" @click="updateConfig"> Save </v-btn>

                <v-btn class="mr-4" @click="reset"> Reset </v-btn>

                <!--v-btn color="warning" @click="resetValidation"> Reset Validation </!--v-btn-->
              </v-col>
            </v-row>
          </v-container>
          
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import SystemBar from "../../../library/system-bar/system-bar";
import AdminMenu from "../../menubar/menubar";
import axios from "axios";

export default {
  components: {

    "admin-menu": AdminMenu,
    "system-bar": SystemBar,
  },
  name: "Commission",
  data() {
    return {
      //url: process.env.VUE_APP_LOYALTY_API_URI,
      commission:"",
      memberType: "Inspector",
      userType: "inspector",
      url: process.env.VUE_APP_LOYALTY_API_URI,
      custId: process.env.VUE_APP_OGF_CUST_ID,
    };
  },
  mounted(){
   //   console.log(this.$route);
   this.getConfig({
      code: "Inspector",
      name: "Commission",
      
      //custId: sessionStorage.getItem("custId"),
    }).then((response) => {
      console.log("getConfig");
      console.log(response);
     
    });
    },
  methods: {

    updateConfig() {
      //
      try {
        this.payload = {
          createBy: sessionStorage.getItem("email"),
          updateBy: sessionStorage.getItem("email"),
          custId: sessionStorage.getItem("custId"),

      cfg_code: "Inspector",
      cfg_name: "Commission",
      cfg_value: this.commission

        };
        return axios
          .put(
            this.url + "/v1/admin/updateConfig",
            this.payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            //console.log(response)
            //this.pointType = response.data.value;
            return response.data;
          });
      } catch (err) {
        this.responseStatus = {
          status: "Error",
          code: 500,
          text: err,
        };
      }
    },

    async getConfig(cfg) {
      //
      try {
        this.payload = {
          createBy: sessionStorage.getItem("email"),
          updateBy: sessionStorage.getItem("email"),
          custId: sessionStorage.getItem("custId"),

          /*code:"Point",
                    name:"Type",
                    */
          ...cfg,
          //custId: sessionStorage.getItem("custId"),
        };
        await axios
          .post(
            this.url + "/v1/admin/getConfigByName",
            this.payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response)
            //this.pointType = response.data.value;
            //return response.data;
            if(response.data.length > 0){
                this.commission = response.data[0].cfg_value
              }
          });
      } catch (err) {
        this.responseStatus = {
          status: "Error",
          code: 500,
          text: err,
        };
      }
    },
  },
};
</script>
